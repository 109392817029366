import { useEffect } from 'react';
import { isPurchaseOrdersFeatureEnabled } from 'src/constants/common';

// TODO: Remove this hook when PO feature is ready to launch
export const useEnablePurchaseOrder = () => {
  const enablePurchaseOrderFlag = 'enablePurchaseOrder';

  useEffect(() => {
    if (localStorage.getItem(enablePurchaseOrderFlag) === null) {
      localStorage.setItem(enablePurchaseOrderFlag, 'false');
    }
  }, []);

  // Return true only if:
  // 1. Not in prod (isPurchaseOrdersFeatureEnabled is true) OR
  // 2. Feature is explicitly enabled in localStorage
  return (
    isPurchaseOrdersFeatureEnabled ||
    localStorage.getItem(enablePurchaseOrderFlag) === 'true'
  );
};
