import { axios } from 'src/lib/axios';
import { fetchAuthSession } from 'aws-amplify/auth';
import {
  APPLICATION_JSON,
  CONTENT_TYPE,
  BACKEND_PATHS,
} from 'src/constants/backend-paths';
import { CSRF_TOKEN_COOKIE } from 'src/constants/common';
import { AxiosError } from 'axios';

interface ApiClient {
  getCsrfToken(): Promise<any>;
  get(path: string): Promise<any>;
  post(
    path: string,
    body: any,
    abortController?: AbortController,
  ): Promise<any>;
  put(path: string, body: any): Promise<any>;
  patch(path: string, body: any): Promise<any>;
  delete(path: string): Promise<any>;
}

type CsrfError = {
  status: 400 | number;
  data: string;
};

const ERROR_MESSAGES = {
  MALFORMED: 'MalformedHttpRequestException',
  CSRF_INVALID: 'CSRF token missing or invalid',
} as const;

const refreshCsrfTokenIfExpired = async () => {
  const token = getCookie(CSRF_TOKEN_COOKIE);
  if (token == null) {
    await OrchestratorApiService.getCsrfToken();
  }
};

const getCookie = (name: string): string | null => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop()?.split(';').shift() ?? null;
  return null;
};

axios.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    if (isCsrfTokenError(error) && !originalRequest._retry) {
      originalRequest._retry = true;
      await OrchestratorApiService.getCsrfToken();
      return axios(originalRequest);
    }
    return Promise.reject(error);
  },
);

const isCsrfTokenError = (
  error: AxiosError,
): error is AxiosError<CsrfError> => {
  return (
    error.response?.status === 400 &&
    typeof error.response.data === 'string' &&
    error.response.data.includes(ERROR_MESSAGES.MALFORMED) &&
    error.response.data.includes(ERROR_MESSAGES.CSRF_INVALID)
  );
};

export const OrchestratorApiService: ApiClient = {
  async getCsrfToken() {
    const authToken = await fetchAuthSession().then((authSession) =>
      authSession.tokens?.idToken?.toString(),
    );
    return axios.get(BACKEND_PATHS.CSRF_REFRESH, {
      headers: {
        [CONTENT_TYPE]: APPLICATION_JSON,
        Authorization: authToken,
        'anti-csrftoken-a2z-request': true,
      },
    });
  },

  async get(path) {
    await refreshCsrfTokenIfExpired();
    const authToken = await fetchAuthSession().then((authSession) =>
      authSession.tokens?.idToken?.toString(),
    );
    return axios.get(path, {
      headers: {
        [CONTENT_TYPE]: APPLICATION_JSON,
        Authorization: authToken,
      },
    });
  },

  async post(path: string, body: unknown, abortController: AbortController) {
    await refreshCsrfTokenIfExpired();
    const authToken = await fetchAuthSession().then((authSession) =>
      authSession.tokens?.idToken?.toString(),
    );
    const config = {
      headers: {
        [CONTENT_TYPE]: APPLICATION_JSON,
        Authorization: authToken,
      },
      signal: abortController?.signal,
    };

    return axios.post(path, body, config);
  },

  async put(path: string, body: unknown) {
    await refreshCsrfTokenIfExpired();
    const authToken = await fetchAuthSession().then((authSession) =>
      authSession.tokens?.idToken?.toString(),
    );
    const config = {
      headers: {
        [CONTENT_TYPE]: APPLICATION_JSON,
        Authorization: authToken,
      },
    };

    return axios.put(path, body, config);
  },

  async patch(path: string, body: unknown) {
    await refreshCsrfTokenIfExpired();
    const authToken = await fetchAuthSession().then((authSession) =>
      authSession.tokens?.idToken?.toString(),
    );
    const config = {
      headers: {
        [CONTENT_TYPE]: APPLICATION_JSON,
        Authorization: authToken,
      },
    };

    return axios.patch(path, body, config);
  },

  async delete(path: string) {
    await refreshCsrfTokenIfExpired();
    const authToken = await fetchAuthSession().then((authSession) =>
      authSession.tokens?.idToken?.toString(),
    );
    const config = {
      headers: {
        [CONTENT_TYPE]: APPLICATION_JSON,
        Authorization: authToken,
      },
    };

    return axios.delete(path, config);
  },
};
